import { FC } from "react";
import Filter from "~/components/filters/Filter";
import {
  useGetSelectedState,
  useSetSelectedState,
} from "~/components/hooks/SelectedState";
import { FarmerProfile } from "~/components/hooks/useFarmerProfile";
import { User } from "~/components/hooks/useUser";
import { STATES_AND_TERRITORIES } from "~/lib/data/states";
import { getSelectChoiceById } from "~/lib/utils/utils";
import { AlertsCards } from "./AlertsCards";

export type AlertCardVersion = "limited" | "full";

export interface AlertsSectionProps {
  user: User;
  farmerProfile?: FarmerProfile;
  version?: AlertCardVersion;
}

export const AlertsSection: FC<AlertsSectionProps> = ({
  user,
  version = "limited",
}) => {
  const setSelectedState = useSetSelectedState();
  const getSelectedState = useGetSelectedState();

  return (
    <div className="flex flex-col space-y-4">
      <Filter
        filterText="Filter by state"
        choices={STATES_AND_TERRITORIES}
        selectedChoice={
          getSelectedState.data
            ? getSelectChoiceById(getSelectedState.data, STATES_AND_TERRITORIES)
            : undefined
        }
        onChoiceSelected={(choice) => {
          if (choice?.id) {
            setSelectedState.mutate({ state: choice.id });
          }
        }}
        preventDeselect
      />
      <AlertsCards
        user={user}
        state={getSelectedState.data}
        version={version}
      />
    </div>
  );
};
