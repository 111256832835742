import cntl from "cntl";
import Link from "next/link";
import React from "react";

interface LinkTextProps {
  href: string;
  children?: React.ReactNode;
}

const LinkText: React.FC<LinkTextProps> = ({ href, children }) => {
  const className = cntl`
    text-darkGreen
    underline
    sm:flex-row
    cursor-pointer
  `;

  return (
    <span className={className}>
      <Link href={href}>{children}</Link>
    </span>
  );
};

export default LinkText;
