import axios, { AxiosError } from "axios";
import { useQuery } from "react-query";

export interface FarmerProfile {
  states: string[];
}

export const FARMER_PROFILE_QUERY_KEY = "farmerProfile";

export const useFarmerProfile = () =>
  useQuery<FarmerProfile, AxiosError>(FARMER_PROFILE_QUERY_KEY, async () => {
    return (await axios.get<FarmerProfile>("/api/proxy/v1/farmer/profile"))
      .data;
  });
